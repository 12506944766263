import request from '../utils/request'
/*
* ============新增账户
* @params{
*
* addAccountVo:{
*      bankaddress: string      开户行
*      bankname:string          银行名称
*      name:string              姓名
*      no:string                账号
*      type:init                充值类型
*      status:init              状态
*     }
* }
* */
export function confAddDo(data) {
    return request({
        url:'/platform/sys/conf/addDo',
        method:'post',
        data
    })
}
export function confEdit(data) {
    return request({
        url:'/platform/sys/conf/editDo',
        method:'post',
        data
    })
}
export function confData(data) {
    return request({
        url:'/platform/sys/conf/data',
        method:'post',
        data
    })
}
export function sysConfigEditData(id) {
    return request({
        url:`/platform/sys/conf/edit/${id}`,
        method:'post',
    })
}
export function sysConfigDelete(id) {
    return request({
        url:`/platform/sys/conf/delete/${id}`,
        method:'post',
    })
}

<template>
    <el-container class="page-container">

        <el-main class="page-main" style="padding: 0 20px">
            <br>
            <el-row class="row-btn-group">
                <el-col :span="24">
                    <el-button type="primary" @click="openAdd">
                        <i class="cw-icon cw-icon-daochu"></i>添加配置项
                    </el-button>
                </el-col>
            </el-row>
            <el-table
                    :data="tableData"
                    @sort-change='pageOrder'
                    size="small"

                    style="width: 100%">
                <el-table-column
                        sortable
                        prop="configKey"
                        label="参数名"

                        width="200">
                </el-table-column>
                <el-table-column
                        prop="configValue"
                        label="参数值"

                        :show-overflow-tooltip="true"
                        width="200">
                </el-table-column>
                <el-table-column
                        prop="note"

                        :show-overflow-tooltip="true"
                        label="说明">
                </el-table-column>
                <el-table-column
                        prop="note"
                        label="操作"
                        width="120">
                    <template slot-scope="scope">
                        <el-dropdown @command="dropdownCommand">
                            <el-button size="mini">
                                <i class="el-icon-setting"></i>
                                操作
                                <span class="el-icon-arrow-down"></span>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item :command="{type:'edit',id:scope.row.configKey}">
                                    修改
                                </el-dropdown-item>
                                <el-dropdown-item :command="{type:'delete',id:scope.row.configKey}"
                                                  v-if="scope.row.configKey.indexOf('App')<0">
                                    删除
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>

            <br>
            <el-pagination
                    @size-change="pageSizeChange"
                    @current-change="pageNumberChange"
                    :current-page="pageForm.pageNumber"
                    :page-sizes="[10, 20, 30, 50]"
                    :page-size="pageForm.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageForm.totalCount">
            </el-pagination>
        </el-main>
        <el-dialog
                title="新增参数"
                :visible.sync="addDialogVisible"
                :close-on-click-modal="false"
                width="40%">
            <el-form :model="formData" ref="addForm" :rules="formRules" label-width="80px">
                <el-form-item prop="configKey" label="参数名">
                    <el-input maxlength="100" placeholder="参数名"
                              v-model="formData.configKey"
                              auto-complete="off" tabindex="1" type="text"></el-input>
                </el-form-item>
                <el-form-item prop="configValue" label="参数值">
                    <el-input maxlength="100" placeholder="参数值"
                              v-model="formData.configValue"
                              auto-complete="off" tabindex="2" type="text"></el-input>
                </el-form-item>
                <el-form-item prop="note" label="说 明">
                    <el-input type="textarea" v-model="formData.note"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                    <el-button @click="addDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="doAdd">确 定</el-button>
                  </span>
        </el-dialog>
        <el-dialog
                title="修改参数"
                :visible.sync="editDialogVisible"
                :close-on-click-modal="false"
                width="40%">
            <el-form :model="formData" ref="editForm" :rules="formRules" label-width="80px">
                <el-form-item prop="configKey" label="参数名">
                    <el-input maxlength="100" placeholder="参数名"
                              v-model="formData.configKey"
                              auto-complete="off" tabindex="1" type="text" disabled></el-input>
                </el-form-item>
                <el-form-item prop="configValue" label="参数值">
                    <el-input maxlength="100" placeholder="参数值"
                              v-model="formData.configValue"
                              auto-complete="off" tabindex="2" type="text"></el-input>
                </el-form-item>
                <el-form-item prop="note" label="说 明">
                    <el-input type="textarea" v-model="formData.note"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                    <el-button @click="editDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="doEdit">确 定</el-button>
                  </span>
        </el-dialog>
    </el-container>
</template>

<script>
    import * as sysConfig from '../../api/sysConfig'

    export default {
        name: "memberList",
        data() {
            return {
                addDialogVisible: false,
                editDialogVisible: false,
                tableData: [],
                pageForm: {
                    pageSize: 10,
                    pageNumber: 1,
                    pageOrderName: "",
                    pageOrderBy: "",
                    totalCount: 0,
                },
                formData: {
                    configKey: "",
                    configValue: "",
                    note: ""
                },
                formRules: {
                    configKey: [
                        {required: true, message: '请输入参数名', trigger: 'blur'},
                    ],
                    configValue: [
                        {required: true, message: '请输入参数值', trigger: 'blur'},
                    ],
                }
            }
        },
        methods: {
            openAdd() {
                this.addDialogVisible = true;
                this.formData = {};//打开新增窗口,表单先清空
            },
            doAdd() {
                this.$refs["addForm"].validate(async (valid) => {
                    if (valid) {//表单验证通过
                        try {
                            let data = await sysConfig.confAddDo(this.formData)
                            this.$message({
                                message: data.msg,
                                type: 'success'
                            });
                            this.addDialogVisible = false;
                            this.pageData();//重新加载分页数据
                        } catch (e) {

                        }
                    }
                });
            },
            doEdit() {

                this.$refs["editForm"].validate(async (valid) => {
                    if (valid) {
                        try {
                            let data = await sysConfig.confEdit(this.formData)
                            this.$message({
                                message: data.msg,
                                type: 'success'
                            });
                            this.editDialogVisible = false;
                            this.pageData();//重新加载分页数
                        } catch (e) {

                        }
                    }
                });
            },
            pageOrder(column) {//按字段排序
                this.pageForm.pageOrderName = column.prop;
                this.pageForm.pageOrderBy = column.order;
                this.pageData();
            },
            pageNumberChange(val) {//页码更新操作
                this.pageForm.pageNumber = val;
                this.pageData();
            },
            pageSizeChange(val) {//分页大小更新操作
                this.pageForm.pageSize = val;
                this.pageData();
            },
            async pageData() {//加载分页数据

                try {
                    let {data} = await sysConfig.confData(this.pageForm)
                    this.tableData = data.list;
                    this.pageForm.totalCount = data.totalCount;
                } catch (e) {

                }

            },
            async dropdownCommand(command) {//监听下拉框事件

                if ("edit" == command.type) {
                    try {
                        let {data} = await sysConfig.sysConfigEditData(command.id)
                        this.formData = data;//加载后台表单数据
                        this.editDialogVisible = true;//打开编辑窗口
                    } catch (e) {

                    }
                }
                if ("delete" == command.type) {
                    try {
                        await this.$confirm('此操作将删除该条数据，确定删除吗？', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        })
                        let data = await sysConfig.sysConfigDelete(command.id)
                        this.$message({
                            message: data.msg,
                            type: 'success'
                        });
                    } catch (e) {
                    }
                }
            }
        },
        mounted() {
            this.pageData();
        }
    }
</script>

<style lang="less" scoped>
    .row-btn-group {
        margin-bottom: 20px;
        .amount-wrapper {
            display: flex;
            font-size: 14px;
            align-items: center;
            .amount {
                color: #ff5477;
            }
        }

    }
</style>

import { render, staticRenderFns } from "./parames.vue?vue&type=template&id=4b437909&scoped=true&"
import script from "./parames.vue?vue&type=script&lang=js&"
export * from "./parames.vue?vue&type=script&lang=js&"
import style0 from "./parames.vue?vue&type=style&index=0&id=4b437909&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b437909",
  null
  
)

export default component.exports